.Toolbar {
  height: 56px;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #703b09;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  box-sizing: border-box;
  z-index: 90;
}

.Toolbar nav {
  height: 100%;
  
}

.Logo {
  height: 80%;
}

@media (max-width: 499px) {
  .DesktopOnly {
    display: none;
  }
  .pDesktop  {
    display: none;
  }
}

@media (min-width: 500px) {
  .pMobile {
    display: none;
  }
}

.Menu {
  cursor: pointer;
}

.pMobile {
  margin: 4px;
  font-size: .7em;
  width: 150px;
  color: white;
}

.pDesktop {
  margin: 4px;
  font-size: .7em;
  width: 150px;
  color: white;
}

@media (min-width: 600px) {
  .pDesktop {
    font-size: .8em;
    width: 200px;
  }
}

@media (min-width: 800px) {
  .pDesktop {
    font-size: .95em;
    width: 430px;
  }
}