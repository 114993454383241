.Modal {
  position: fixed;
  z-index: 500;
  background-color: white;
  width: 70%;
  border: 1px solid #ccc;
  box-shadow: 1px 1px 1px black;
  padding: 16px;
  left: 15%;
  top: 30%;
  box-sizing: border-box;
  transition: all 0.3s ease-out;
}

@media (min-width: 600px) {
  .Modal {
      width: 500px;
      left: calc(50% - 250px);
  }
}

@media (max-width: 320px) {
  .Modal {
    width: 80%;
    left: 10%;
    top: 20%;
  }
} 