.Burger {
  width: 80%;
  margin: 2% auto;
  height: 250px;
  overflow: auto;
  text-align: center;
  font-weight: bold;
  font-size: 1.2rem;
}

@media (max-width: 479px) and (min-height: 400px) {
  .Burger {
    width: 80%;
    height: 170px;
  }
}

@media (min-width: 500px) and (min-height: 400px) {
  .Burger {
    width: 80%;
    height: 300px;
  }
}

/* @media (min-width: 500px) and (min-height: 401px) {
  .Burger {
    width: 80%;
    height: 400px;
  }
} */

@media (min-width: 800px) and (min-height: 401px) {
  .Burger {
    width: 600px;
    height: 300px;
  }
}

@media (min-width: 1000px) and (min-height: 700px) {
  .Burger {
    width: 600px;
    height: 300px;
  }
}
